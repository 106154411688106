exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-contact-lenses-js": () => import("./../../../src/pages/services/contact-lenses.js" /* webpackChunkName: "component---src-pages-services-contact-lenses-js" */),
  "component---src-pages-services-eye-examinations-js": () => import("./../../../src/pages/services/eye-examinations.js" /* webpackChunkName: "component---src-pages-services-eye-examinations-js" */),
  "component---src-pages-services-neuro-optometry-js": () => import("./../../../src/pages/services/neuro-optometry.js" /* webpackChunkName: "component---src-pages-services-neuro-optometry-js" */),
  "component---src-pages-services-vision-therapy-js": () => import("./../../../src/pages/services/vision-therapy.js" /* webpackChunkName: "component---src-pages-services-vision-therapy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-visual-language-js": () => import("./../../../src/pages/visual-language.js" /* webpackChunkName: "component---src-pages-visual-language-js" */)
}

